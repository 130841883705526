import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import contact from "../images/contact.webp"
import {SendButton} from "../components/sendbutton"

const ContactPage = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")

    const [sent, setSent] = useState(false)
    
    function sendMsg(e){
        e.preventDefault()
        console.log(name,email,subject,message)
        
        fetch("http://localhost:5000/v1.1/contact", {
            method : "POST",
            body : JSON.stringify({"name" :name, "email" : email, "subject" : subject, "message" : message}),
            headers : {
                "Accept" : "application/json",
                "Content-Type" : "application/json"
            }
        }).then(response => response.json())
        .then(data => {console.log(data); setSent(true)})
    }

    useEffect(() => {
        if(sent){
            console.log(sent)
        }
    }, sent)

    return(
        <Layout siteTitle="Contact">
            <div className="bg-gray-200 2xl:pb-10 ">
                <h1 className="text-6xl sm:text-7xl font-bold text-center px-10 pt-10 uppercase">Contact Us</h1>
                <div className="grid md:grid-flow-col grid-flow-row md:auto-cols-fr gap-x-10 pt-10 pb-12">
                    <div className="bg-cover bg-top" style={{backgroundImage : `url(${contact})`, height : "600px"}} />

                    <div className="w-full bg-white grid grid-flow-row gap-3 justify-items-center">
                        <h3 className="pt-10 text-3xl ">Let's get in touch</h3>
                        <p className="max-w-xl text-center">
                            You can call us or text us at <a href="tel:714-872-9535">(714) 872-9535</a>, send us an email at <a href="mailto:info@knockoutphoto.com">info@knockoutphoto.com</a>,
                            or use the form below.
                        </p>

                        <div className="w-full text-center space-y-10 px-16 py-10 max-w-xl">
                            <div className="w-full grid grid-flow-row grid-cols-2 gap-5">
                                <input onChange={e => setName(e.target.value)} placeholder="Name*" required className="border border-t-0 border-r-0 border-l-0 border-solid border-black w-full transition duration-300 ease-in-out hover:border-blue-300 focus:border-blue-400" />
                                <input onChange={e => setEmail(e.target.value)}  placeholder="Email*" required className="border border-t-0 border-r-0 border-l-0 border-black w-full transition duration-300 ease-in-out hover:border-blue-300 focus:border-blue-400" />
                            </div>
                            <input onChange={e => setSubject(e.target.value)}  placeholder="Subject" className="border border-t-0 border-r-0 border-l-0 border-black w-full transition duration-300 ease-in-out hover:border-blue-300 focus:border-blue-400" />
                            <textarea onChange={e => setMessage(e.target.value)}  placeholder="Message" className="border border-t-0 border-r-0 border-l-0 border-black w-full h-40 transition duration-300 ease-in-out hover:border-blue-300 focus:border-blue-400" />
                            <SendButton sent={sent} sendMsg={sendMsg} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ContactPage