import React from 'react'


export function SendButton(props){
    if(props.sent){
        return(
            <button  
            className="font-light text-xl border-transparent border border-t-0 border-r-0 border-l-0 bg-green-400 p-2 rounded"
            onClick={props.sendMsg}>
                Sent!
            </button>
        )
    }
    else{
        return(
            <button 
            onClick={props.sendMsg} 
            className="transition duration-150 ease-in-out font-light text-xl border-transparent border border-t-0 border-r-0 border-l-0 hover:border-black">
                Send
            </button>
        )
    }
}
